exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-en-about-index-tsx": () => import("./../../../src/pages/en/about/index.tsx" /* webpackChunkName: "component---src-pages-en-about-index-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-pic-index-tsx": () => import("./../../../src/pages/en/pic/index.tsx" /* webpackChunkName: "component---src-pages-en-pic-index-tsx" */),
  "component---src-pages-en-posts-mdx-frontmatter-slug-tsx": () => import("./../../../src/pages/en/posts/{mdx.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-en-posts-mdx-frontmatter-slug-tsx" */),
  "component---src-pages-en-tags-index-tsx": () => import("./../../../src/pages/en/tags/index.tsx" /* webpackChunkName: "component---src-pages-en-tags-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pic-index-tsx": () => import("./../../../src/pages/pic/index.tsx" /* webpackChunkName: "component---src-pages-pic-index-tsx" */),
  "component---src-pages-posts-mdx-frontmatter-slug-tsx": () => import("./../../../src/pages/posts/{mdx.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-posts-mdx-frontmatter-slug-tsx" */),
  "component---src-pages-tags-index-tsx": () => import("./../../../src/pages/tags/index.tsx" /* webpackChunkName: "component---src-pages-tags-index-tsx" */)
}

